/****************************************
input
****************************************/
input:not([type="checkbox"]):not([type="radio"]):not([type="range"]) {
    background-color: $color-light-1;
    border: none;
    width: 100%;
    max-width: 100%;
    height: 6.3rem;
    padding: 0 2.9rem;
    outline: none;


    font-size: 1.6rem;
    color: $color-grey;
}

/****************************************
textarea
****************************************/
textarea {
    background-color: $color-light-1;
    border: none;
    width: 100%;
    max-width: 100%;
    height: 16.7rem;
    resize: none;
    padding: 2.2rem 2.9rem;
    outline: none;

    font-size: 1.6rem;
    color: $color-grey;
}

/****************************************
checkbox
****************************************/
input[type="checkbox"] {
    position: absolute;
    opacity: 0;

  
    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;

        font-size: 1.4rem;
        color: $color-grey;
        display: flex;
        align-items: center;
    }
  
    & + label::before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 2.4rem;
        height: 2.4rem;
        background: $color-light-1;
        @include animateIt;
    }
  
    &:checked + label:before {
        background: $primary-color;
    }
    
    &:disabled + label {
        color: $color-grey;
        cursor: auto;
    }
  
    &:disabled + label:before {
        box-shadow: none;
        background: $color-grey;
    }
  
    &:checked + label::after {
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 1.5rem;
        position: absolute;
        top: .2rem;
        left: .4rem;
        color: #fff;
        display: block;
    }
}

/****************************************
form
****************************************/
form {
    .row {
        & + .row {
            margin-top: 1rem;
        }

        @media (max-width: 992px) {
            & + .row {
                margin-top: 0;
            }
            input:not([type="checkbox"]):not([type="radio"]):not([type="range"]),
            textarea,
            button {
                margin-top: 1rem;
            }
        }
    }
    .button {
        margin-left: 0;
    }
}

/****************************************
file upload
****************************************/
.file-upload {
    cursor: pointer;
    background-color: $color-light-1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
        display: none;
    }
    .label {
        font-size: 1.6rem;
        color: $color-grey;
        margin-left: 2.9rem;
    }
    .file {
        font-size: 1.4rem;
        color: $color-grey;
        margin-right: 1rem;
    }
    .select {
        &-wrapper {
            height: 100%;
            display: inline-flex;
            align-items: center;
        }        
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 600;
        color: #fff;
        height: 100%;
        background-color: $color-light;
        padding: 0 3rem;
        @include animateIt;
    }
    &:hover {
        .select {
            background-color: $primary-color;
        }
    }
}