/****************************************
sidebar
****************************************/
.sidebar {
    @media (max-width: 992px) {
        margin-top: 5rem;
    }
    > * {
        &:nth-child(n+2) {
            margin-top: 3rem;
        }
    }
}

/****************************************
search
****************************************/
.sidebar {
    @media (max-width: 992px) {
        padding: 0 2rem;
    }
    .search {
        position: relative;
        input:not([type="checkbox"]):not([type="radio"]):not([type="range"]) {
            width: 100%;
            height: 5.5rem;
            background-color: $primary-color-light-2;
            border: none;
            outline-color: transparent;
            

            font-size: 1.6rem;
            color: $color-dark;

            padding: 0 5rem 0 2.8rem;

        }
        button {
            background: transparent;
            border: none;
            position: absolute;

            top: 50%;
            right: 2.8rem;
            transform: translateY(-50%);
            
            i {
                font-size: 1.6rem;
                color: $primary-color;
            }
        }
    }
}

/****************************************
category
****************************************/
.sidebar {
    .category {
        &-wrapper {
            background-color: $primary-color-light-2;
            padding: 3.7rem 5rem;
        }
        h4 {
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 4rem;
            text-transform: uppercase;
            color: $primary-color;
            margin-bottom: 2.3rem;
        }
        ul {
            list-style: none;
            li {
                a {
                    font-size: 2rem;
                    line-height: 4rem;
                    font-weight: 500;
                    color: $color-grey;
                    @include animateIt;
                    &::before {
                        content: '\f101';
                        font-family: 'Font Awesome 5 Free';
                        font-size: 1.4rem;
                        font-weight: 900;
                        margin-right: 1rem;
                        @include animateIt;
                    }

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

/****************************************
sidebar page
****************************************/
.sidebar-page {
    &-wrapper {
        padding: 8.3rem 0 12rem 0;
    }
    @media (max-width: 992px) {
        &-wrapper {
            padding: 5rem 0;
        }
    }
}