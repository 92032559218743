
a {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
}

ul {
    padding-left: 0;
}

p {
    font-size: 1.8rem;
    line-height: 3.4rem;

    &.large {
        font-size: 2rem;
    }

    &.small {
        font-size: 1.6rem;
    }

    @media (max-width: 992px) {
        font-size: 2rem;
        &.small {
            font-size: 1.8rem;
        }
    }
}

.menu-list{
    font-size: 28px;
   font-weight: 700;
   
}

h1 {
    font-size: 6rem;
    font-weight: 900;
    line-height: 0.8;
    text-align: left;
    @media (max-width: 992px) {
        font-size: calc(4rem + (6 - 4) * ((100vw - 200px) / (992 - 200)));

    }    
}

h2 {
    font-size: 5.4rem;
    line-height: 0.8;
    font-weight: 900;
    text-align: left;
    @media (max-width: 992px) {
        font-size: calc(3rem + (4.4 - 3) * ((100vw - 200px) / (992 - 200)));
        line-height: calc(4.6rem + (6 - 4.6) * ((100vw - 200px) / (992 - 200)));
    }
}

h3 {
    font-size: 4rem;
    line-height: 3.4rem;
    font-weight:900;
    text-align: left;
}

h4 {
    font-size: 2rem;
    line-height: 3.4rem;
}