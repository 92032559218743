// font
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;400;500;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

// plugins
@import url('plugins/bootstrap.min.css');
@import url('plugins/all.min.css');

// variables
@import '_variables.scss';

// mixins
@import '_mixins.scss';

// default
@import '_default.scss';

// variables
@import '_classes.scss';

// icon box
@import '_preloader.scss';

// icon box
@import '_icon-box.scss';

// slider
@import '_slider.scss';

// footer
@import '_footer.scss';

// button
@import '_button.scss';

// team
@import '_team.scss';

// career
@import '_career.scss';

// blog
@import '_blog.scss';

// form
@import '_form.scss';

// form page
@import '_form-page.scss';

// sidebar
@import '_sidebar.scss';

// service
@import '_service.scss';

// service
@import '_navigation.scss';

// main
@import '_main.scss';

// youtube
@import '_youtube.scss';

