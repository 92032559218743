




/****************************************
general
****************************************/
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

@media (max-width: 992px) {
    .responsive-image{
        display: none;
    }
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
    margin-top: 0;
    padding: 0;

    // causes white line issue on the mobile devices
    @media (max-width: 992px) {
        font-size: 57.5%;
    }

    @media (max-width: 800px) {
        font-size: 55.5%;
    }

    @media (max-width: 768px) {
        font-size: 52.5%;
    }

    @media (max-width: 600px) {
        font-size: 50.5%;
    }

}

body {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
}

.main-wrapper {
    width: 100%;
    padding: 0;
    border: var(--border) solid #034694;
    
    > div {
        width: 100%;
    }
}

/****************************************
cta section
****************************************/
.cta-section {
    &-wrapper {
        padding: 17.2rem 0 20rem 0;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .button-group {
        margin-top: 4.3rem;
        @media (max-width: 992px) {
            margin-top: 1rem;
        }
    }

    &-1 {
        .cta-section-wrapper {
            .button-group {
                margin-top: 3.4rem;
            }
            @media (max-width: 992px) {
                padding-bottom: 5.7rem;
            }
        }
    }
    &-signature {
        img {
            margin-top: 6.5rem;
        }
    }
}

/****************************************
testimonial section
****************************************/
.testimonial {
    &-wrapper {
        padding: 13.7rem 0 15rem 0;
        position: relative;

        .background-img {
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;

            background-repeat: no-repeat;
            object-fit: cover;
            background-position: center right;

            z-index: 1;
        }
    }

    .box-slider {
        margin-top: 3.9rem;
        position: relative;
        z-index: 2;
    }

    &-heading-content {
        position: relative;
        z-index: 2;
    }

    @media (max-width: 992px) {
        &-wrapper {
            padding-top: 5rem;
            padding-bottom: 0;
            .row {
                padding-left: 2rem;
                padding-right: 2rem;
            }
            .background-img {
                display: block;
                position: relative;
                width: 100%;
                height: 50rem;
                margin-top: 5rem;
                
                background-position: top center;

            }
        }
    }
}

/****************************************
video lightbox
****************************************/
.video-lightbox {
    .gprev,
    .gnext {
        display: none;
    }
}

/****************************************
page bg
****************************************/
.page-bg {
    margin-top: 20px;
    &-wrapper {
        position: relative;
        &-image {
            position: absolute;
            top: -2.5%;
            right: 0;
            z-index: 1;

            width: 30%;
            height: 100%;

            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    &-content {
        position: relative;
        z-index: 2;
    }
}

/****************************************
icon box slider section
****************************************/
.icon-box-section {
    &-wrapper {
        padding: 13.7rem 0 15rem 0;
        background-color: $primary-color-light-2;
    }

    &-inner {
        position: relative;
        z-index: 2;
    }

    .icon-box-slider {
        margin-top: 3.9rem;
    }
}

.pattern-bg {

    position: relative;
    &-image {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom right;

        z-index: 1;

        @media (max-width: 992px) {
            background-size: cover;
        }

    }
    &-content {
        position: relative;
      
        z-index: 2;
    }
}

/****************************************
intro section
****************************************/
.intro {
    padding-top: 13.7rem;
    text-align: left;

  

    &-content {
   
        p:not(:last-child) {
            margin-top: 2.5rem;
        }
        img {
            margin-top: 3.6rem;
        }
        @media (max-width: 992px) {
            width: 100%;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    @media (min-width: 1400px) {
        .vertical-txt {
            margin-top: 8.6rem;
        }
    }

    @media (max-width: 1399px) {
        .vertical-txt {
            margin-top: 5.6rem;
        }
    }

    &-bg {
    
        @media (max-width: 992px) {
            margin-top: 6rem;
          
        }
    }
}

/****************************************
hero section
****************************************/
.hero {
    position: relative;
    
    &-slide {
        width: 100%;
        

        @media (max-width: 992px) {
            
            min-height: 100%;
        }

        &-inner {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;

            background-repeat: no-repeat;
            background-size: cover;
        }

        &-content {
            text-align: center;
            width: 48%;
            margin: 27.7rem auto 50.5rem auto;

            @media (max-width: 992px) {
                width: 80%;
                margin: 20rem auto 30rem auto;
            }

            .button-group {
                display: flex;
                align-items: center;
                margin: 5rem auto 0 auto;
                width: max-content;

                p {
                    font-size: 1.6rem;
                    color: $color-dark;
                    margin: 0 0 0 3rem;
                    > a {
                        font-size: inherit;
                        color: $primary-color;
                    }
                }

                @media (max-width: 992px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 3rem;
                    .button {
                        margin: 0;
                    }
                    p {
                        display: block;
                        width: 100%;
                        margin: 1rem 0 0 1.4rem;
                    }
                }
            }
        }
    }
    &-pagination {
        margin-top: -25rem;
        z-index: 100;
        position: absolute;
        width: 100%;

        &-wrapper {
            position: relative;
        }

        @media (max-width: 992px) {
            justify-content: space-between;
        }

        .visible-bullet {
            @include animateIt;
        }

        .swiper-slide {
            height: auto;
        }

        // .swiper-wrapper {
        //     display: flex;
        //     align-items: flex-end;
        // }

        @media (max-width: 992px) {
            &-wrapper {
                max-width: 100%;
                padding: 0;
            }
        }

        &-bullet {
            background-color: transparent;
            height: max-content;
            padding-top: 6.8rem;
            cursor: pointer;
            @include animateIt;

            p {
                font-size: 1.6rem;
                @media (max-width: 992px) {
                    font-size: 1.8rem;
                }
            }

            > div {
                display: inline-block;
                background-color: #fff;
                max-height: 100%;
                padding-left: 5rem;
                padding-right: 5rem;
                margin: 0 .8rem;
                outline: transparent solid .4rem;
                @include animateIt;
            }

            &-active {
                > div {
                    
                    transform: translateY(-5rem);
                    outline: $primary-color solid .4rem;
                }
            }

            @media (max-width: 992px) {
                > div {
                    padding-left: 3rem;
                    padding-right: 3rem;
                }
            }
        }
    }
}

/****************************************
hero 2 section
****************************************/
.hero-2 {
    &-wrapper {
        position: relative;
        padding-top: 8.4rem;
        margin-top: 100px;
        background-color: $primary-color-light-2;
        overflow: hidden;
        @media (max-width: 992px) {
            padding-top: 12rem;
        }
    }

    &-content {
        line-height: 0.6;
        position: relative;
        z-index: 2;
        .button-group {
            margin-top: 3.6rem;
            p {
                margin: 1.5rem 0 0 1.2rem;
            }
        }
        @media (max-width: 992px) {
            text-align: center;
            h1 {
                width: 70%;
                margin: 0 auto;
            }
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-image {
        position: relative;
        max-width: 100%;
        object-fit: cover;
        .main {
            transform: translateX(6rem);
            max-width: 100%;
            position: relative;
            z-index: 2;

            @media (min-width: 992px) {
                height: 74.3rem;
            }
        }
        .circle {
            width: 58rem;
            height: 58rem;
            border-radius: 50%;
            background-color: $primary-color-light-3;

            position: absolute;
            top: 0;

            z-index: 1;

            img {
                position: absolute;
                top: 8rem;
                left: 11.6rem;
                max-width: 100%;
            }

        }
        @media (max-width: 992px) {
            text-align: center;
            margin-top: 3rem;
            .main {
                transform: none;
                max-width: 80%;
            }
            .circle {
                left: 50%;
                transform: translateX(-70%);
                width: 60%;
                height: 60%;
            }
        }
    }

    @media (max-width: 992px) {
        .container {
            max-width: 96%;
        }
    }

    &-absolute-img {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        @media (max-width: 992px) {
            display: none;
        }
    }

    &-pagination {
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
    }
}

/****************************************
icon text section
****************************************/
.icon-text {
    &-wrapper {
        padding: 5rem 0;
        @media (max-width: 992px) {
            padding: 7rem 0 0 0;
        }
    }
    &-box {
        &-large {
            width: 52%;
            margin: 0 auto 10rem auto;
            @media (max-width: 992px) {
                width: 80%;
                margin: 0 auto 5rem auto;
            }
        }
    }
    &-row {
        > div[class*='col'] {
            padding: 14rem 0;
            border-top: .1rem solid $color-grey-2;
            &:nth-child(odd) {
                border-right: .1rem solid $color-grey-2;
            }
            &:nth-child(even) {
                .icon-text-box {
                    margin-left: auto;
                }
            }
            @media (max-width: 992px) {
                padding: 5rem 0;
                &:nth-child(even) {
                    .icon-text-box {
                        margin-left: initial;
                    }
                }
                &:nth-child(odd) {
                    border-right: none;
                }
                .icon-text-box {
                    margin: 0 auto !important;
                }
            }
        }
        .icon-text-box {
            width: 53%;
        }
    }
}

/****************************************
tabs section
****************************************/
.tab-section {
    &-wrapper {
        padding: 5rem 0;
        background-color: $primary-color-light-2;
        @media (max-width: 992px) {
            padding: 5rem 0;
        }
    }
    .nav {
        display: flex;
        border: none;
        flex-wrap: initial;
        .nav-link {
            position: relative;
        }
        @media (max-width: 992px) {
            padding: 0 2rem;
        }
        .nav-link {
            width: max-content;
            height: max-content;
            border: none;
            text-align: center;
            color: unset;
            background-color: transparent;

            &-placeholder {
                display: none;
                @media (max-width: 992px) {
                    display: block;
                }
            }

            div {
                background-color: #fff;
                padding: 7.3rem 4rem 6.7rem 4rem;
                outline: transparent solid .4rem;
            }



            h3 {
                font-size: 1.6rem;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: .1rem;
                line-height: 2.4rem;
                color: $primary-color;
                margin-bottom: 1.9rem;
            }

            p {
                font-size: 1.6rem;
                line-height: 2.4rem;
                color: $color-grey;
            }

            &.active {
                div {
                    outline: $primary-color solid .4rem;
                }
            }

            @media (min-width: 992px) {
                &:nth-child(n+3) {
                    margin-top: 1.5rem;
                }
            }
        }

        @media (min-width: 992px) {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            transform: none !important;
        }
    }
    .tab-content {
        .tab-pane {
            > p {
                margin: 2.4rem 0 3.4rem 0;
            }
        }
        .button-group {
            display: flex;
            align-items: center;
            margin-left: 0;
            .button {
                margin: 0 2rem 0 0;
            }
            p {
                margin: 0;
            }
        }
        @media (max-width: 992px) {
            margin-top: 5rem;
            padding: 0 2rem;
            .button-group {
                flex-wrap: wrap;
                .button-group {
                    margin-top: 0;
                }
                p {
                    margin: 2rem 0 0 0;
                }
            }
        }
    }
}

// .tab-section {
//     &-wrapper {
//         padding: 15rem 0;
//         background-color: $primary-color-light-2;
//     }
//     .nav {
//         display: flex;
//         border: none;
//         .nav-link {
//             border: none;
//             background-color: #fff;
//             text-align: center;
//             color: unset;

//             padding: 7.3rem 4rem 6.7rem 4rem;
//             outline: transparent solid .4rem;

//             &:nth-child(n+3) {
//                 margin-top: 3rem;
//             }


//             h3 {
//                 font-size: 1.6rem;
//                 font-weight: 600;
//                 text-transform: uppercase;
//                 letter-spacing: .1rem;
//                 line-height: 2.4rem;
//                 color: $primary-color;
//                 margin-bottom: 1.9rem;
//             }

//             p {
//                 font-size: 1.6rem;
//                 line-height: 2.4rem;
//                 color: $color-grey;
//             }

//             &.active {
//                 outline: $primary-color solid .4rem;
//             }
//         }
//     }
//     .tab-content {
//         .tab-pane {
//             > p {
//                 margin: 2.4rem 0 3.4rem 0;
//             }
//         }
//         .button-group {
//             display: flex;
//             align-items: center;
//             p {
//                 margin: 0 0 0 2rem;
//             }
//         }
//     }
// }


/****************************************
icon text 2 section
****************************************/
.icon-text-2 {
    &-wrapper {
        padding-top: 7rem;
    }
    &-header {
        margin-bottom: 4.2rem;
        .paragraph {
            margin: 2.5rem 0;
        }
        @media (max-width: 992px) {
            margin-bottom: 2rem;
        }
    }
    .row {
        @media (max-width: 992px) {
            padding: 0 2rem;
        }
    }
    &-row {
        div[class*='col'] {
            padding: 9rem 0 6rem 0;
            &:nth-child(3n+2) {
                border-left: .1rem solid $color-grey-2;
                border-right: .1rem solid $color-grey-2;
                display: flex;
                justify-content: center;
            }
            &:nth-child(n+4) {
                border-top: .1rem solid $color-grey-2;
            }
            &:nth-child(3n+3) {
                display: flex;
                justify-content: flex-end;
            }
            @media (max-width: 992px) {
                display: flex;
                padding: 5rem 0 4rem 0;
                &:nth-child(3n+2) {
                    border: none;
                    justify-content: flex-start;
                }
                &:nth-child(3n+3) {
                    justify-content: flex-start;
                }
                &:nth-child(n+4) {
                    border-top: none;
                }
                &:not(last-child):not(:first-child) {
                    border-top: .1rem solid $color-grey-2 !important;
                }
            }
        }
        &.row {
            @media (max-width: 992px) {
                padding: 0 2rem 0 4rem;
            }
        }
    }
    &-single {
        width: 66%;
        h4 {
            margin: 4rem 0 1rem 0;
        }
        @media (max-width: 992px) {
            text-align: center;
            margin: 0 auto;
            .icon-circle {
                margin: 0 auto;
            }
        }
    }
}

/****************************************
team
****************************************/
.team {
    &-wrapper {
        padding: 6rem 0 14rem 0;
        @media (max-width: 992px) {
            padding: 5rem 0;
        }
    }
    &-header {
        margin-bottom: 9.2rem;
        p {
            margin: 2.5rem 0 0 0;
        }
        @media (max-width: 992px) {
            margin-bottom: 5rem;
            p {
                margin: 1.5rem 0 0 0;
            }
        }
    }
    &-member-row {
        margin-top: -9.5rem;
        > div[class*="col"] {
                margin-top: 9.5rem;
        }
        @media (max-width: 992px) {
            margin-top: -3rem;
            > div[class*="col"] {
                margin-top: 3rem;
            }
        }
    }

    .row {
        @media (max-width: 992px) {
            padding: 0 2rem;
        }
    }
}

/****************************************
page header
****************************************/
.page-header {
    &-wrapper {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;

        padding: 26.2rem 0 9.2rem 0;

        @media (max-width: 992px) {
            padding: 15rem 0 5rem 0;
        }
    }
    &-content {
        @media (max-width: 992px) {
            padding: 0 2rem 0 2rem;
        }
        p {
            margin-top: 24rem;
            @media (max-width: 992px) {
                margin-top: 5rem;
            }
        }
    }
    &-list {
        margin-top: 9rem;
        &-1 {
            margin-top: 19.5rem;
        }
        &-content {
            ul {
                list-style: none;
                li {
                    display: inline-block;
                    &:not(:last-child) {
                        margin-right: 3.8rem;
                    }
                }
            }
        }
        @media (max-width: 992px) {
            &-content {
                padding: 0 2rem;
            }
            &-1 {
                margin-top: 12rem;
            }
        }
    }
}

/****************************************
contact
****************************************/
.contact {
    &-wrapper {
        padding: 9.5rem 0 15rem 0;
        @media (max-width: 992px) {
            padding: 5rem 2rem;
        }
    }
    &-header {
        p {
            margin-top: 3.2rem;
        }
    }
    &-detail {
        &-row {
            margin-top: 5rem;

            > div[class*='col'] {
                &:not(:last-child) {
                    position: relative;
                    &::after {
                        content: '';
                        width: .1rem;
                        height: 130%;
                        background-color: $color-light-2;
                        position: absolute;
                        top: -30%;
                        right: 20%;

                        display: block;

                        @media (max-width: 1200px) {
                            right: 10%;
                        }

                        @media (max-width: 576px) {
                            display: none;
                        }
                    }
                }
                &:nth-child(n+3) {
                    @media (max-width: 992px) {
                        border-top: .1rem solid $color-light-2;
                        padding-top: 5rem;
                    }
                }

                &:nth-child(even) {
                    &::after {
                        @media (max-width: 992px) {
                            display: none;
                        }
                    }
                } 
            }

            @media (max-width: 992px) {
                margin-top: 0;
                > div[class*='col'] {
                    margin-top: 5rem;
                }
            }

            @media (max-width: 575px) {
                display: flex;
                justify-content: flex-start;
                > div[class*='col'] {
                    width: 100%;
                    &:not(:first-child) {
                        border-top: .1rem solid $color-grey-2;
                        padding-top: 5rem;
                        margin-top: 2.5rem;
                    }
                }
            }

            @media (max-width: 400px) {
                justify-content: flex-start;
            }
        }
        &-single {
            .icon-circle {
                margin-left: 2.1rem;
                
            }
            h6 {
                font-size: 1.2rem;
                font-weight: 600;
                letter-spacing: .01rem;
                margin-top: 3.6rem;
                @media (max-width: 992px) {
                    font-size: 1.3rem;
                }
            }
            .social {
                    list-style: none;
                    li {
                        display: inline-block;
                        a {
                            font-size: 2rem;
                            line-height: 3.4rem;
                            color: $color-light;
                            @include animateIt;
                            &:hover {
                                color: $primary-color;
                            }
                            @media (max-width: 992px) {
                                font-size: 2.2rem;
                            }
                        }
                        + li {
                            margin-left: 1.4rem;
                        }
                    }
            }

            @media (max-width: 575px) {
                display: flex;
                h6 {
                    margin-top: 0;
                }
                > div {
                    margin-left: 3rem;
                }
            }
        }
    }
    &-location {
        &-row {
            margin-top: 5.3rem;
            > h3 {
                font-size: 2.4rem;
                font-weight: 500;
                color: $color-dark;
                margin-bottom: 3.7rem;
            }
            > div[class*='col'] {
                &:nth-of-type(n+3) {
                    margin-top: 3rem;
                }
            }

            @media (max-width: 992px) {
                margin-top: 5rem;
                > h3 {
                    margin-bottom: 0;
                }
                > div[class*='col'] {
                    &:nth-child(n+2) {
                        margin-top: 3rem;
                    }
                }
            }
        }
        &-single {
            display: grid;
            grid-template-columns: 1fr 1fr;
            @media (max-width: 600px) {
                grid-template-columns: 1fr;
                grid-template-rows: 30rem 1fr;
            }
            figure {
                width: 100%;
                height: 32.7rem;
                background-color: $primary-color;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &-content {
                width: 100%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: $primary-color-light-2;
                h5 {
                    font-size: 2rem;
                    margin-bottom: 1.4rem;
                }
                a {
                    display: block;
                }
                p {
                    margin-bottom: 0;
                }

                @media (max-width: 600px) {
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 5rem;
                }
            }
        }
    }
    &-form {
        &-wrapper {
            background-color: $primary-color-light-2;
            padding: 14.5rem 0 15rem 0;
            @media (max-width: 992px) {
                padding: 5rem 2rem;
            }
        }
        &-header {
            margin-bottom: 3.3rem;
            h2 {
                margin-bottom: 3.3rem;
            }
        }
        form {
            input:not([type="checkbox"]):not([type="radio"]), textarea {
                background-color: #fff;
            }
        }
    }
}

/****************************************
career
****************************************/
.career {
    &-wrapper {
        padding: 14rem 0 15rem 0;
    }
    @media (max-width: 992px) {
        &-wrapper {
            padding: 5rem 2rem;
        }
    }
}

/****************************************
section wrapper
****************************************/
.section-wrapper {
    @media (max-width: 992px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        &-1 {
            padding-top: 5rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}