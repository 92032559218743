@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

:root {
  --mainColor: #034694;
  --mainColorLight: #5767aa;
  --secondaryColor: #566bc0;
  --textColor: #eee;
}

.intro-content{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
  padding: 50px;
  border-radius: 10px;
  max-width: 1000px;
  margin: auto;
}

.button-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-intro{
  font-size: 32px;
  padding: 15px;
 
}


.title-intro-2{
  font-size: 32px;
  padding: 15px;
  line-height: 0.9;
  margin-top: 20px;
  letter-spacing: -1px;
}



header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  padding: 0 2rem;
  z-index: 10;
  background-color: #034694;
  color: var(--textColor);
}

.my-journey-title{
	font-size: 32px;
	margin-bottom: 20px;
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  font-size: 23px;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  visibility: hidden;
  opacity: 0;
  font-size: 3.8rem;
  z-index: 999;
}

.paragraph-lg{
  text-align: left;
}

header div,
nav {
  display: flex;
  align-items: center;
  z-index: 12;
}

.social-icons {
  font-size: 28px;
}

.icons {
  margin: 10px;
  cursor: pointer;
}

a:hover{
  color: #888888 !important;
}

.icons:hover {
  color: #888888;
}

@media only screen and (min-width: 1024px) {
  .nav-btn {
    display: none;
  }
  .social-icons-2 {
    display: none;
  }
  .email-phone{
    display: none;
  }
  .menu-logo-2{
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  .social-icons {
    display: none;
  }

  .social-icons-2 .icons{
    display: flex;
    margin: 0;
    padding: 5px;
    font-size: 33px;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    font-weight: 900;
    font-size: 52px;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 5.5rem;
    cursor: pointer;
 
  }
}
.menu-logo-2{
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
  grid-gap: 10px;
 
}
.image-grid-3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  grid-gap: 10px;
 
}

button{
  --color: #0077ff;
  font-family: inherit;
  display: inline-block;
  width: 10em;
  height: 3.6em;
  line-height: 2.5em;
  overflow: hidden;
  cursor: pointer;
  margin: 20px;
  font-size: 12px !important;
  z-index: 1;
  color: var(--color);
  border: 2px solid var(--color);
  border-radius: 6px !important;
  position: relative;
  padding: 1px;
  font-weight: 800;
}

button::before {
  position: absolute;
  content: "";
  background: var(--color);
  width: 150px;
  height: 200px;
  z-index: -1;
  border-radius: 50%;
}

button:hover {
  color: white;
}

button:before {
  top: 100%;
  left: 100%;
  transition: 0.3s all;
}

button:hover::before {
  top: -30px;
  left: -30px;
}

@media screen and (max-width: 568px) {
  .grid-image-3{
    height: 250px;
  width: 250px;
  margin:auto;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
  }
  .image-grid-3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
    grid-gap: 10px;
   
  }
  
}


@media only screen and (max-width: 1024px) {
  .grid-image{
height: 450px;
width: 450px;
margin: auto;
object-fit: cover;
border-radius: 5px;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
transition: transform 0.3s ease-in-out;
  }
}

.grid-image-2{
  height: 250px;
width: 250px;
margin:auto;
object-fit: cover;
border-radius: 5px;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
transition: transform 0.3s ease-in-out;
}

.grid-image-3{
  height: 320px;
width: 320px;
margin:auto;
object-fit: cover;
border-radius: 5px;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
transition: transform 0.3s ease-in-out;
}


.grid-image {

  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}

.grid-image:hover {
  transform: scale(1.05) rotate(1deg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
}

.grid-image-2:hover {
  transform: scale(1.05) rotate(1deg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
}

.grid-image-3:hover {
  transform: scale(1.05) rotate(1deg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
}

.menu-logo {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
