/****************************************
navigation
****************************************/
.navigation {
    &-inner {
        position: fixed;
        top: 10.6rem;
        left: 0;
        z-index: 500;

        // width: 97.9%;
        width: 100%;
        padding: 0 5rem 0 5rem;

        border-left: var(--border) solid $primary-color;
        border-right: var(--border) solid $primary-color;

        display: flex;
        justify-content: space-between;        
        background-color: transparent;

        @include animateIt;
    }

    &-menu {
        .parent {
            list-style: none;
            > li {
                display: inline-block;
                position: relative;
                > a {
                    position: relative;
                    z-index: 50;
                    > span {
                        font-size: 2.8rem;
                        color: $color-dark;
                        font-weight: 700;
                    }
                }
                & + li {
                    margin-left: 4.5rem;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 5rem;
                    z-index: 1;
                }

                .child {
                    position: absolute;
                    top: 8rem;
                    opacity: 0;
                    visibility: hidden;
                    list-style: none;
                    min-width: 25rem;
                    
                    background-color: #fff;
                    border: .2rem solid $primary-color;
                    box-shadow: 0px 10px 10px 0px rgba(0,0,0,.1);
                    -webkit-box-shadow: 0px 10px 10px 0px rgba(0,0,0,.1);
                    -moz-box-shadow: 0px 10px 10px 0px rgba(0,0,0,.1);
                    @include animateIt;

                    padding: 1rem 2rem;

                    li {
                        a {
                            display: block;
                            width: 100%;                        
                            
                            padding: 1.5rem 0 1.5rem 2rem;
                            @include animateIt;

                            span {
                                font-size: 1.6rem;
                                color: $color-dark;
                                @include animateIt;
                            }

                            &:hover {
                                background-color: #fff;
                                span {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    .child {
                        top: 4rem;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        &-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: 200%;
            background-position: center center;
            display: none;
        }

        .mobile-header {
            display: none;
            justify-content: space-between;
            align-items: center;
            padding: 0 40px;
            margin: 21px 0 24px 0;
            ul {
                list-style: none;
                margin: 0;
                li {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: 35px;
                    height: 35px;
                    background-color: #fff;
                    border-radius: 50%;
                    i {
                        font-size: 14px;
                        color: $primary-color;
                    }
                    &:nth-child(n+2) {
                        margin-left: .5rem;
                    }
                }
            }
        }

        .search {
            display: none;
            position: relative;
            input {
                width: 100%;
                height: 5.5rem;
                background-color: $primary-color-light-2;
                border: none;
                outline-color: transparent;
                
    
                font-size: 1.6rem;
                color: $color-dark;
    
                padding: 0 5rem 0 2.8rem;
    
            }
            button {
                background: transparent;
                border: none;
                position: absolute;
    
                top: 50%;
                right: 2.8rem;
                transform: translateY(-50%);
                
                i {
                    font-size: 1.6rem;
                    color: $primary-color;
                }
            }
        }

        .tagline {
            width: 35%;
            font-size: 14px;
            font-weight: 500;
            line-height: 2rem;
            color: #fff;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 2.3rem;
            display: none;
        }
        
        @media (max-width: 992px) {
            position: absolute;
            top: calc(var(--border) * -1 - 5.8rem);
            left: calc(-100% - 6rem);
            width: calc(100% + 4rem);
            height: 100vh;
            background-color: $primary-color;
            // padding: 10rem 5rem;

            @include animateIt;

            &-image {
                display: block;
            }

            .close-button  {
                display: none;
                position: relative;
                z-index: 500;
            }

            .mobile-header {
                display: flex;
                position: relative;
                z-index: 10;
            }

            .tagline {
                display: block;
            }

            .parent {
                position: relative;
                z-index: 10;
                > li {
                    display: block;
                    &::after {
                        display: none;
                    }
                    > a {
                        display: block;
                        > span {
                            display: block;
                            color: #130c55;
                            width: 100%;
                            padding: 15px 40px;
                            font-size: 38px;
                            font-weight: 700;
                            text-align: center;
                        }
                    }

                    span {
                        &::before {
                            display: none;
                        }
                    }

                    &.main-search {
                        display: none;
                    }

                    &.has-child {
                        a {
                            position: relative;
                            &::after {
                                content: '\f107';
                                font-family: 'Font Awesome 5 Free';
        
                                font-size: 20px;
                                font-weight: 900;
                                color: #fff;
        
                                position: absolute;
                                top: 50%;
                                right: 40px;
                                transform: translateY(-50%);

                                @include animateIt;
                            }
                        }
                        .child {
                            box-shadow: none;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            border: none;
                            a {
                                &::after {
                                    content: '\f105';
                                    color: $primary-color;
                                }
                            }
                        }
                    }

                    .child {                        
                        position: relative;
                        top: 0 !important;
                        opacity: 1 !important;
                        visibility: visible;
                        display: none;
                        padding: 0;
                        background-color: #fff;
                        li {
                            a {
                                padding: 0;
                                span {
                                    display: inline-block;
                                    color: $color-grey;
                                    font-size: 20px;
                                    font-weight: 500;
                                    padding: 11.5px 40px;
                                }
                            }
                            &:first-child {
                                padding-top: 19.75px;
                            }
                            &:last-child {
                                padding-bottom: 19.75px;
                            }
                        }
                    }

                    & + li {
                        margin-left: 0;
                    }

                    &.has-child.dropped {
                        > a {
                            &::after {
                                transform: translateY(-50%) rotate(180deg);
                            }
                        }
                        
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .tagline {
                width: 46%;
            }
        }

        @media (max-width: 730px) {
            top: calc(var(--border) * -1 - 3.7rem);
        }

        @media (max-width: 350px) {
            .mobile-header {
                flex-direction: column;
                ul {
                    margin-top: 3rem;
                }
            }
        }

        @media (min-width: 992px) {
            .has-child {
                .child {
                    position: absolute !important;
                    max-height: initial !important;
                    -webkit-transition: all 0.25s ease !important;
                    -moz-transition: all 0.25s ease !important;
                    -o-transition: all 0.25s ease !important;
                    transition: all 0.25s ease !important;
                }
            }
        }

    }

    &.scrolled {
        .navigation-inner {
            top: 0;
            padding: 3.1rem 5rem 2rem 5rem;
            background-color: #fff;
            @media (max-width: 992px) {
                padding: 3.1rem 2rem 3.1rem 2rem;
            }
        }
        .navigation-menu {
            @media (max-width: 992px) {
                top: 0;
                
            }
            .parent {
                li {
                    &:hover {
                        .child {
                            top: 5.7rem;
                        }
                    }
                }
            }
        }
    }

    &-bar {
        width: 4rem;
        height: 16px;
        display: none;
        flex-direction: column;
        // justify-content: flex-end;
        justify-content: space-between;
        cursor: pointer;
        @include animateIt;
   
        
        div {
            display: block;
            width: 100%;
            height: 2px;
            background-color: $primary-color;
        }
        // div + div {
        //     margin-top: .5rem;
        // }

        @media (max-width: 992px) {
            display: flex;
        }
    }

    @media (max-width: 992px) {
        &-inner {
            top: 8rem;
            align-items: center;
            padding: 0 2rem 0 2rem;
            .search {
                display: block;
            }
        }
    }

    @media (max-width: 730px) {
        &-inner {
            top: 5rem;
        }
    }

    &.shown {
        .navigation-menu {
            left: -2rem;
        }
    }
}

/****************************************
top menu
****************************************/
.top-menu {
    &-wrapper {
    
    }

    .tagline {
        p {
            font-size: 1.3rem;
            font-weight: 500;
            color: $color-grey;
            margin: 0;
        }
    }

    .menu {
        ul {
            list-style: none;
            margin: 0;
            li {
                display: inline-block;

                & + li {
                    margin-left: 3.3rem;
                }

                &.phone {
                    font-size: 1.2rem;
                    font-weight: 500;
                    color: $primary-color;
                    @include animateIt;
                    &:hover {
                        color: $color-dark;
                    }
                }
            }
        }
    }

    @media (max-width: 730px) {
        display: none;
    }
}

/****************************************
search
****************************************/
.main-search {
    cursor: pointer;
    &-lightbox {
        .gprev,
        .gnext {
            display: none;
        }
    }

    &-content {
        position: relative;
        input:not([type="checkbox"]):not([type="radio"]):not([type="range"]) {
            padding-right: 6rem;
        }
        button {
            background: transparent;
            border: none;
            position: absolute;

            top: 50%;
            right: 2.8rem;
            transform: translateY(-50%);
            
            i {
                font-size: 1.6rem;
                color: $primary-color;
            }
        }
    }
}