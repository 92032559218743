/****************************************
service
****************************************/
.service {
    &-header {
        margin: 7rem 0 9rem 0;
        h2 {
            margin-bottom: 3rem;
        }
        @media (max-width: 992px) {
            margin: 5rem 0;
        }

        @media (max-width: 992px) {
            padding: 0 2rem;
        }
    }
    &-single {
        width: 68%;
        h3 {
            margin-top: 4.1rem;
        }
        p {
            margin: 3.9rem 0 3.6rem 0;
        }
    }
    &-list {
        > div[class*='col'] {
            padding: 10.2rem 0 9rem 0;
            &:nth-child(even) {
                border-left: .1rem solid $color-grey-2;
            }
            &:nth-child(n+3) {
                border-top: .1rem solid $color-grey-2;
            }
        }
        @media (max-width: 992px) {
            > div[class*='col'] {
                padding: 5rem 0 5rem 0;
                &:nth-child(even) {
                    border-left: none;
                }
                &:nth-child(n+3) {
                    border-top: none;
                }
                &:not(:first-child) {
                    border-top: .1rem solid $color-grey-2;
                }
            }
        }
    }
}

/****************************************
service detail
****************************************/
.service-detail {
    &-wrapper {
        padding-top: 7.8rem;
        @media (max-width: 992px) {
            padding: 5rem 2rem 0 2rem;
        }
    }
    &-content {
        h2 {
            margin-bottom: 3.8rem;
        }
        p + p {
            margin-top: 3.2rem;
        }
    }
    @media (max-width: 992px) {
        &-icon {
            margin-bottom: 5rem;
            width: max-content;
            margin: 0 auto 5rem auto;
            text-align: center;
            img {
                width: 60%;
                margin: 0 auto;
            }
        }
        &-content {
            text-align: center;
        }
    }
}

/****************************************
service feature
****************************************/
.service-feature {
    &-wrapper {
        padding: 14rem 0 13rem 0;
        @media (max-width: 992px) {
            padding: 5rem 2rem;
        }
    }
    &-single {
        width: 79%;
        h3 {
            margin-bottom: 3.3rem;
        }
        @media (max-width: 992px) {
            width: 100%;
            h3 {
                margin-bottom: 2rem;
            }
        }
    }
    &-list {
        > div[class*='col'] {
            &:nth-child(n+3) {
                margin-top: 4.8rem;
            }
            @media (max-width: 992px) {
                &:nth-child(n+3) {
                    margin-top: 0;
                }
                &:nth-child(n+2) {
                    margin-top: 3rem;
                }
            }
        }
    }
}