/********************
preloader
********************/
.preloader {
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100vw; 
    height: 100vh; 
    z-index: 999; 
    @include animateIt; 
    transition-delay: 1s;
    background-color: #034694;
    display: flex;
    justify-content: center;
    align-items: center;

    $spinkit-size: 8rem !default;

    .sk-folding-cube {
        $cubeCount: 4;
        $animationDuration: 2.4s;
        $delayRange: ($animationDuration / 2);
      
        width: $spinkit-size;
        height: $spinkit-size;
        position: relative;
        margin: auto;
        transform: rotateZ(45deg);
      
        .sk-cube {
          float: left;
          width: 50%;
          height: 50%;
          position: relative;
          transform: scale(1.1);
        }
      
        .sk-cube:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          animation: sk-folding-cube-angle $animationDuration infinite linear both;
          transform-origin: 100% 100%;
        }
      
        // Rotation / angle
        @for $i from 2 through $cubeCount {
          .sk-cube-#{$i} {
            transform: scale(1.1) rotateZ((90deg * ($i - 1)));
          }
        }
      
        @for $i from 2 through $cubeCount {
          .sk-cube-#{$i}:before {
            animation-delay: ($delayRange / $cubeCount * ($i - 1));
          }
        }
    }
      
    @keyframes sk-folding-cube-angle {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
    }
}
body.loaded .preloader{ transform: translateY(-100%);  }