/****************************************
team member
****************************************/
.team-member {
    text-align: center;
    figure {
        position: relative;
        overflow: hidden;
        height: 49.3rem;
        background-color: $primary-color;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .team-social {
            background-color: $primary-color;
            display: flex;
            justify-content: center;
            padding: 1.5rem 0;
            position: absolute;
            bottom: -6.7rem;
            width: 100%;
            @include animateIt;
            ul {
                margin: 0;
                li {
                    list-style: none;
                    display: inline-block;
                    a {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.5rem;
                        color: #fff;
                        background-color: transparent;
                        border: .2rem solid #fff;
                        width: 4rem;
                        height: 4rem;
                        border-radius: 50%;
                        @include animateIt;

                        &:hover {
                            background-color: #fff;
                            color: $primary-color;
                        }
                    }
                    & + li {
                        margin-left: 2rem;
                    }
                }
            }
        }
        &:hover {
            .team-social {
                bottom: 0;
            }
        }
    }
    figcaption {
        h4 {
            margin-top: 2.3rem;
        }
        p {
            margin: 0;
        }
        a {
            span {
                font-size: 1.8rem;
                line-height: 3.4rem;
                color: $primary-color;
            }
        }
    }
}