/****************************************
career accordion
****************************************/
.career-accordion {
    &-item {
        border: none;
        position: relative;
        padding: 7rem 0 5rem 0;

        &:not(:last-child) {
            border-bottom: .1rem solid $color-light-2;
        }

        @media (max-width: 992px) {
            padding: 5rem 0;
        }

        &-header {
            cursor: pointer;
            position: relative;
            > div {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .content {
                    width: 70%;
                    .paragraph {
                        margin: -.7rem 0 1rem 0;
                    }
                    .teaser {
                        span {
                            @include animateIt;
                        }
                        .dots {
                            opacity: 1;
                            visibility: visible;
                            display: inline-block;
                        }
                        .remaining {
                            max-height: 0;
                            overflow: hidden;
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                }
                .button-group {
                    @include animateIt(1s);
                    position: absolute;
                    top: 60%;
                    right: 0;
                    
                    transform: translateY(-50%);
                    // &:not(.collapsed) {
                    //     bottom: 80%;
                    // }
                    &:not(.collapsed) {
                        top: 40%;
                        
                    }
                }

                @media (max-width: 992px) {
                    width: 100%;
                    align-items: flex-start;
                    flex-direction: column;
                    .content {
                        width: 100%;
                    }
                    .button-group {
                        width: 100%;
                        position: relative;
                        top: initial;
                        right: initial;
                        transform: none;
                        margin: 0 auto;
                        @include animateIt;
                        .button {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 0;
                        }

                        // &:not(.collapsed) {
                        //     width: 20%;
                        // }
                    }
                }
            }
        }
        &.shown {
            div[data-bs-toggle="collapse"] {
                span {
                    width: 0;
                    overflow: hidden;
                    margin: 0;
                }
            }
        }

        .accordion-body {
            padding: 0 0 8rem 0;

            @media (max-width: 992px) {
                padding: 0;
            }

            h3 {
                margin: 2rem 0 1.5rem 0;
            }

            .form-heading {
                margin: 3.5rem 0 4.6rem 0; 
            }

            ul {
                list-style: none;
                // margin: 1.5rem 0;
                li {
                    font-size: 2rem;
                    color: $color-grey;
                }
                &.dotted {
                    margin: 3rem 0 0 0;
                    li {
                        &::before {
                            content: '\f101';
                            font-family: 'Font Awesome 5 Free';
                            color: $primary-color;
                            font-weight: 900;
                            margin-right: 1.2rem;
                        }
                    }
                }
            }

            form {
                
                .input-group {
                    display: flex;
                    input {
                        max-width: calc(50% - 1rem);
                    }
                    > *:last-child {
                        margin-left: 1rem !important;
                    }
                    > *:first-child {
                        margin-left: 0 !important;
                    }
                    &:nth-child(n+2) {
                        margin-top: 1rem;
                    }

                    .file-upload {
                        width: calc(50% - 1rem);
                        input {
                            width: 100%;
                        }
                    }
                }

                button {
                    width: calc(50% - 1rem);
                    text-align: center;
                    margin: 0;
                    div {
                        text-align: center;
                        justify-content: center;
                    }
                }

                @media (max-width: 992px) {
                    .input-group {
                        input {
                            max-width: 100%;
                            &:nth-child(n+2) {
                                margin-top: 0;
                            }
                        }

                        > *:last-child {
                            margin-left: 0 !important;
                            margin-top: 1rem !important;
                        }

                        .file-upload {
                            width: 100%;
                            height: 6.3rem;
                        }

                        .button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}